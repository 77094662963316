<template>
    <div class='Home'>
        <img class=Cave src="https://cave.cs.columbia.edu/img/CaveImage.6998568d.png" />
        <div class=text>
            <p>
                The Columbia Imaging and Vision Laboratory (CAVE) at Columbia University is dedicated to the development of advanced vision systems. Our research focuses on three broad areas: the creation of vision sensors, the design of physics based models for vision, and the development of algorithms for scene interpretation. Our work is motivated by applications in the fields of digital imaging, machine vision, robotics, human-machine interfaces, computer graphics, and displays.
            </p>
            <p class=urlwrapper1> 
                <a class=url href="https://fpcv.cs.columbia.edu/">First Principles of Computer Vision</a>
            </p>
            <p>
                <a class=url href="http://www.bigshotcamera.com/">Bigshot Digital Camera</a>
            </p>
        </div>
        <br>
    </div>
    
    <div v-if="loaded" class=news>
        <hr>
        <h1 class=title >News</h1>
        <p class=newsdata v-for="entry in news"  :key="entry.id" v-html="entry">
            
        </p>
    </div>
    <div class='end'></div>
</template>
<script>
import axios from 'axios'
export default {
    name:'Home',
    data(){
        return{
            news:this.news,
            loaded:this.loaded
        }
    },
    created(){
        // axios.get("http://localhost:8080/api/Statics/news/news.json").then((res)=>{
        //     if (res.data.length != 0){
        //     this.news = res.data
        //     this.loaded = true
        //     }
        //     else
        //         this.loaded=false
        // })


            axios.get("https://cave.cs.columbia.edu/Statics/news/news.json").then((res)=>{
            if (res.data.length != 0){
            this.news = res.data
            this.loaded = true
            }
            else
                this.loaded=false
        })
    }
}
</script>

<style scoped>
/* :root{
    overflow-x:hidden;
} */
.Home{
    padding-top: 4em;
    width:80%;
    display:flex;
    margin:0 auto;
    align-items:center;
    justify-content:center;
}
.end{
    padding-bottom:1em;
}
.news{
    /* width: 800px; */
    max-width: 1200px;
    min-height: 20vh;
    margin:auto;
    font-size: 18px;
    padding-top:60px;
}
.title{
        font-size:22px;
        font-weight: 600;
        font-family:'Raleway', sans-serif;
        padding-top:20px;
        padding-bottom: 20px;
}

.newsdata{
    margin-bottom:15px;
    text-align: left;
}
.Cave{
    width:625px;
    height:576px;
}
.text{
    letter-spacing: .05em;
    line-height:1.5em;
    text-align:center;
    margin-left:50px;
    width:500px;
    max-height:576px;
    font-size:22px;
    font-family:'-apple-system','BlinkMacSystemFont',"Segoe UI",'Roboto',"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji", 'SFMono-Regular','Menlo,Monaco','Consolas',"Liberation Mono","Courier New",monospace;
}
.urlwrapper1{
    padding-top:1em;
}
.url{
    width:500px;
    text-decoration: none;
    color:black;
    font-family: "Raleway";
    font-size: 22px;
    text-align: center;
    font-weight:700;
    margin:0;
}
.url:hover{
    color:grey;
}
</style>